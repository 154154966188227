import api from '../../api/api';
import * as ACTIONS from '../actionTypes';
import * as MUTATION from '../mutationTypes';
import {cacheAction} from 'vuex-cache';

export default {
  [ACTIONS.PAYMENT_BUY]: async (_, params) => {
    return await api.paymentApi.buy(params);
  },
  [ACTIONS.GET_PACKAGES_CACHED]: cacheAction(({
    cache,
    commit,
  }, params) => {
    cache.dispatch(ACTIONS.GET_PACKAGES, params).then((packages) => {
      commit(MUTATION.UPDATE_PACKAGES_LIST, packages);
    });
  }),
  [ACTIONS.GET_PACKAGES]: async ({commit}, params = {}) => {
    if (!params?.hasOwnProperty('limit')) {
      params.no_limit = 1;
    }
    const packages = await api.paymentApi.packages(params);
    commit(MUTATION.UPDATE_PACKAGES_LIST, packages);
    return packages;
  },
  [ACTIONS.GET_SUBSCRIPTIONS_CACHED]: cacheAction(({
    cache,
    commit,
  }, params) => {
    cache.dispatch(ACTIONS.GET_SUBSCRIPTIONS, params)
        .then((packages) => {
          commit(MUTATION.UPDATE_SUBSCRIPTIONS_LIST, packages);
        });
  }),
  [ACTIONS.GET_SUBSCRIPTIONS]: async ({commit}, params = {}) => {
    if (!params?.hasOwnProperty('limit')) {
      params.no_limit = 1;
    }
    const subscriptions = await api.paymentApi.subscriptions(params);
    commit(MUTATION.UPDATE_SUBSCRIPTIONS_LIST, subscriptions);
    return subscriptions;
  },
  [ACTIONS.GET_PACKAGE]: async (state, params) => {
    return await api.paymentApi.package(params);
  },
  [ACTIONS.GET_SUBSCRIPTION]: async (state, params) => {
    return await api.paymentApi.subscription(params);
  },
  [ACTIONS.GET_ORDERS]: async ({commit}, params) => {
    const orders = await api.paymentApi.orders(params);
    commit(MUTATION.UPDATE_ORDERS_LIST, orders.data);
    return orders.data;
  },
  [ACTIONS.GET_ORDER_BY_ID]: async (_, {order_id: orderId} = {}) => {
    if (!orderId) return false;
    return await api.paymentApi.orderById({orderId});
  },
  [ACTIONS.APPLY_COUPON]: async (_, params) => {
    return await api.paymentApi.applyCoupon(params);
  },
  [ACTIONS.UNSUBSCRIBE]: async (_, params) => {
    return await api.paymentApi.unsubscribe(params);
  },
  [ACTIONS.GET_STRIPE_PORTAL]: async () => {
    return await api.paymentApi.getStripePortal();
  },
  [ACTIONS.GET_USER_CARDS]: async () => {
    return await api.paymentApi.getUserSavedCards();
  },
  [ACTIONS.START_TRIAL]: async (_, {id, ...rest}) => {
    return await api.paymentApi.startTrial({
      subscription_id: id,
      ...rest,
    });
  },
  [ACTIONS.GET_CLIENT_SECRET_STRIPE]: async (_, params) => {
    return await api.paymentApi.getClientSecretStripe(params);
  },
};
