import {VUE_APP_CHANNEL_NAME} from '@/const';
export const CHANNEL_KEY_API = VUE_APP_CHANNEL_NAME + '.json';
export const API_KEY_API = '/get_api_key';
export const PROFILE_API = '/profile';
export const USER_ACCESS_API = '/user/access';
export const LOGIN_API = 'v2/auth/login';
export const LOGOUT_API = 'auth/logout';
export const REGISTER_API = 'v2/auth/register';
export const SOCIAL_AUTH_API = 'v2/auth/social';
export const EMAIL_VERIFY_API = 'auth/email-verify';
export const PASSWORD_RESET_REQUEST_API = 'auth/password-reset-request';
export const PASSWORD_RESET_API = 'auth/password-reset';
export const ADD_SESSION_API= 'auth/add_session';
export const SETTINGS_API = '/settings';
export const MENU_API = '/menu';
export const SLIDER_API = '/slides/web';
export const PAGES_API = '/pages';
export const COLOR_API = '/color-settings/web';
export const CLIP_API = '/clip';
export const CLIPS_FOR_EVENT_API = '/clips/event';
export const SHARED_CLIPS_API = '/shared-clip';
export const DOWNLOAD_CLIP_API = '/clip/download';
export const USER_CLIPS_API = `/clips/user`;
export const EVENTS_WITH_CLIP_API = '/events/with_clip';
export const CUSTOM_FORM_API = '/custom-forms';
export const EVENTS_API = '/events';
export const DOWNLOAD_EVENT_API = 'download-event';
export const EVENT_CATEGORIES_API = '/events-categories';
export const HOMEPAGE_EVENTS_API = '/events-for-homepage';
export const FAQ_API = '/faq';
export const FAVORITES_API = '/favorites';
export const FAVORITE_API = '/favorite';
export const FAVORITE_EVENTS_API = '/events/favorite';
export const GEO_API = '/geo';
export const PARTICIPANTS_API = '/participants';
export const PARTICIPANT_GROUP_API = '/participant-groups';
export const PARTICIPANT_GROUP_PROPERTIES_API = '/participant-group-properties';
export const STRIPE_BUY_API = 'stripe/buy';
export const PACKAGES_API = 'packages';
export const SUBSCRIPTIONS_API = 'subscriptions';
export const ORDERS_API = 'orders';
export const APPLY_COUPON_API = 'apply_coupon';
export const STRIPE_UNSUBSCRIBE_API = 'stripe/unsubscribe';
export const STRIPE_PORTAL_API = '/stripe/customer-portal';
export const STRIPE_CARDS_API = 'stripe/list-cards';
export const STRIPE_INTENT_API = '/stripe/setup-intent';
export const STRIPE_TRIAL_SUBSCRIPTION_API = '/stripe/subscription/start_trial';
export const VOD_ITEMS_API = 'vod-media-items';
export const VOD_CATEGORIES_API = 'vod-media-categories';
export const VOD_SEARCH_API = 'vod-media/search';
export const VOD_RAILS_API = '/vod-media-rails';
export const EVENTS_LOCATIONS_API = '/events-locations';
export const DELETE_ACCOUNT_API = '/delete-account-confirm';


export const NO_API_KEY_URLS = [
  CHANNEL_KEY_API,
  API_KEY_API,
  MENU_API,
  PAGES_API,
  FAQ_API,
  COLOR_API,
  SETTINGS_API,
  SLIDER_API,
  EVENTS_LOCATIONS_API,
  SUBSCRIPTIONS_API,
  PACKAGES_API,
  PARTICIPANTS_API,
  PARTICIPANT_GROUP_API,
  DELETE_ACCOUNT_API,
];
